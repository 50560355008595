import React from "react";
import "../../index.css";
import { graphql, StaticQuery, Link } from "gatsby";
// import "./projects.css";

export default function LatestPosts({ data }) {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <div className="">
            <div className="">
              <h2 className="heading mt-10 mb-6">Archives</h2>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <div className="" key={node.id}>
                  <Link to={`${node.fields.slug}`}>
                    <div className="text-sm md:text-base hover:bg-black hover:text-white">
                      {node.frontmatter.title.length > 50
                        ? `${node.frontmatter.title.substring(0, 50)}...`
                        : node.frontmatter.title}
                      <br />
                      <div className="text-xs mt-2 mb-1">
                        {node.frontmatter.date}, {node.frontmatter.category},{" "}
                        {node.timeToRead} minute read
                        <br />
                        {/*<br />*/}
                        {node.excerpt.length > 100
                          ? `${node.excerpt.substring(0, 100)}...`
                          : node.excerpt}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
}

export const query = graphql`
  query LatestNotes {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
      limit: 3
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
            description
          }
        }
      }
    }
  }
`;
